<template>
	<div class="user__view">
		<div class="header">
			<h2>Ajouter un thème</h2>
		</div>
		<div class="control">
			<v-btn color="#272727" class="white--text" @click="$router.push({path: '/theme'})" tile x-large>
				Retour
			</v-btn>
			<v-btn color="green" class="white--text" :loading="loading" @click="validate()" tile x-large >
				Sauvegarder
			</v-btn>
		</div>
		<div class="displayer">
			<div class="right">
				<div class="user__card">
					<div class="annuaire">
						<v-form
							ref="form"
							v-model="valid"
							lazy-validation
						>
							<v-text-field v-model="theme.name" :rules="nomRules" label="Nom"></v-text-field>
						</v-form>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
import { utils } from '../utils.js'

export default {
	name: 'ThemeAdd',
	data() {
		return {
			valid: false,
			nomRules: [
				v => !!v || 'Champs requis'
			],
			theme: {
				name: ''
			},
			dialog: false,
			loading: false
		}
	},
	methods: {
		...mapActions("global", [
			"addThemeAction"
		]),
		...mapMutations("global", [
			"addSuccess",
			"addErrors"
		]),
		validate () {
			if (this.$refs.form.validate() == false) {
				return
			}
			this.addTheme()
		},
		addTheme() {
			const _this = this;
			this.loading = true;

			this.addThemeAction({
				name: this.theme.name
			})
			.then(() => {
				_this.addSuccess("Le thème a été ajouté.")
				_this.$router.push({ path: '/theme' });
			})
			.catch(err => {
				_this.addErrors(utils.ajax.normalize_error(err))
			})
			.finally(() => {
				_this.loading = false
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.user__view {
	min-height: 100vh;

	.header {
		display: flex;
		align-items: center;
		padding: 20px;
		border-bottom: 1px solid #dbdbdb;

		h2 {
			font-weight: normal;
			margin-bottom: 20px;
			width: 100%;
			margin: 0;

			span {
				font-size: 16px;
				color: #969696;
			}
		}
	}

	.control {
		position: fixed;
		bottom: 0;
		width: 100%;
		z-index: 100;

		.v-btn {
			width: 50%;
		}
	}

	.displayer {
		display: flex;
		height: 100%;

		.left {
			width: 25%;
			min-height: 100vh;
			border-right: 1px solid #dbdbdb;
		}

		.right {
			width: 75%;
			padding: 1% 2% 5% 2%;
			height: fit-content;

			.user__card {
				padding: 10px;

				h4 {
					color: #c1c1c1;
					font-weight: normal;
				}

				.user {
					margin-top: 2%;

					.swicth__status {
						display: flex;
						align-items: center;

						h3 {
							margin-right: 10px;
						}
					}

					.info__image {
						margin-top: 15px;
					}

					.upload__image {
						margin-top: 25px;
						.displayer {
							display: flex;
							gap: 50px;
						}

						.grid__gallery {
							width: 50%;
							border-right: 2px dashed #c7c7c7;
							min-height: 250px;

							.row {
								padding: 10px;
							}

							.gallery {
								cursor: pointer;
								position: relative;

								.selected {
									position: absolute;
									top: 0;
									right: 0;
									border-radius: 50%;
									background: green;
									z-index: 2;
									width: 25px;
									height: 25px;
									display: flex;
									align-items: center;
									justify-content: center;

									i {
										font-size: 15px;
									}
								}
							}
						}

						.upload__gallery {
							width: 50%;
						}

						.loading__file {
							margin-top: 50px;
						}
					}

				}
			}
		}
	}
}
</style>